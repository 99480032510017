<template>
	<div class="ipad-theme-home">

		<div class="top-bar">
			<div class="network wifi"><ui-icon name="chirp/network-1" :size="16" /></div>
			<div class="network phone"><ui-icon name="chirp/network-2" :size="16" /></div>
			<div class="network battery"><ui-icon name="chirp/network-3" :size="16" /> 100%</div>
		</div>

		<div class="apps">

			<div class="app" v-hammer:tap="() => toggle_chirp('feed')">
				<div class="app-icon">
					<ui-icon class="icon" name="menu/menu-chirp" :size="40" />
					<div v-if="new_chirps > 0" class="new">{{ new_chirps }}</div>
				</div>
				<div class="app-name">Chirp</div>
			</div>

			<div class="app" v-hammer:tap="() => toggle_chirp('messages')">
				<div class="app-icon">
					<ui-icon class="icon" name="chirp/chirp-messages" :size="40" />
					<div v-if="new_messages > 0" class="new">{{ new_messages }}</div>
				</div>
				<div class="app-name">Messages</div>
			</div>

			<ui-link to="/game/files" class="app">
				<div class="app-icon">
					<ui-icon class="icon" name="menu/menu-files" :size="40" />
					<div v-if="new_files > 0" class="new">{{ new_files }}</div>
				</div>
				<div class="app-name">Files</div>
			</ui-link>

			<!-- <div class="app" v-hammer:tap="show_settings">
				<div class="app-icon">
					<ui-icon class="icon" name="menu/menu-resources" :size="40" />
				</div>
				<div class="app-name">Settings</div>
			</div> -->

			<div class="app" v-hammer:tap="logout">
				<div class="app-icon">
					<ui-icon class="icon" name="logout" :size="40" />
				</div>
				<div class="app-name">Log out</div>
			</div>

		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	computed: {
		...mapGetters([ 'app', 'game', 'chirp', 'logged_user', 'files', 'current_question', 'progress', 'current_question_conversation' ]),
		new_chirps() {
			return this.chirp.feed.filter((m) => { return !m.read_by.includes(this.logged_user.id) && m.sender.id !== this.logged_user.id }).length
		},
		new_messages() {
			return this.chirp.messages.filter((m) => { return !m.read_by.includes(this.logged_user.id) && m.sender.id !== this.logged_user.id }).length +
				this.new_decision_conversation
		},
		new_decision_conversation() {
			if (this.current_question_conversation) {
				let answer = this.current_question_conversation.answer.find((a) => { return this.progress.selected_answers.includes(parseInt(a.id)) })
				if (!answer) return 1
			}
			return 0
		},
		new_files() {
			return this.files.documents.filter((doc) => { return doc && !doc.read }).length
				+ this.files.images.filter((image) => { return image && !image.read }).length
				+ this.files.inventory.filter((inv) => { return inv && !inv.read }).length
				+ this.files.memories.filter((memory) => { return memory && !memory.read }).length
		},
	},
	methods: {
		toggle_chirp(route) {
			if (this.app.device === 'mobile' || this.app.device === 'tablet-vertical' || this.app.device === 'tablet-horizontal') {
				this.$router.push({ path: '/game/chirp/' + route })
				this.$store.dispatch('set_chirp_route', { name: route })
			} else {
				if (this.chirp.route && this.chirp.route.name === route) {
					this.$store.commit('EDIT_CHIRP_STATE', { route: null })
				} else {
					this.$store.dispatch('set_chirp_route', { name: route })
				}
			}
		},
		show_settings() {
			this.$store.commit('EDIT_APP_STATE', { settings: !this.app.settings })
		},
		logout() {
			this.$store.dispatch('logout')
		},
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.ipad-theme-home
	width 100%
	height 100%
	.top-bar
		height 32px
		width 100%
		padding 0 8px
		flex flex-start center
		gap 8px
		.network
			height 16px
			width 16px
			&.battery
				position absolute
				right 12px
				top 8px
				width 48px
				flex flex-end center
				gap 6px
				font-size 1.1rem
				font-weight 600
	.apps
		width 100%
		height calc(100% - 32px)
		display flex
		align-items flex-start
		justify-content flex-start
		flex-wrap wrap
		gap 40px
		padding @gap
		.app
			width 80px
			height (80px + 8px + 24px)
			text-align center
			cursor pointer
			&:hover .app-icon
				background-color var(--color-profile-hexagon-active-bg)
				color var(--color-profile-hexagon-active-text)
			.app-icon
				width 80px
				height 80px
				margin 0 0 8px 0
				border-radius 8px
				background-color var(--color-profile-hexagon-bg)
				display flex
				align-items center
				justify-content center
				color var(--color-profile-hexagon-text)
				.new
					position absolute
					right -4px
					bottom -4px
					width 28px
					height 28px
					border-radius 50%
					flex center center
					box-shadow 0 0 0 3px var(--color-bg)
					background-color var(--color-menu-new-badge-bg)
					font-size 1.8rem
					color var(--color-menu-new-badge-text)
			.app-name
				height 24px

</style>