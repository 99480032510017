<template>
	<div class="main-view">
		<div v-if="emblem_visible && game.scenario.enable_stats" class="background">
			<ui-icon name="emblems/gacc" />
		</div>
		<!-- <div v-else-if="emblem_visible && logged_user && logged_user.role && logged_user.role.category" class="background classic" :data-category="logged_user.role.category" :data-journal="current_journal"></div> -->

		<ipad-theme-home v-if="app.is_ipad_theme && $route.name === 'game'" />

		<router-view />

		<div v-if="popupResumeGameVisible" class="resume-game-popup">
			<div class="resume-game-popup-content">
				<div class="text">
					<p>A game is in progress.</p>
					<p>Would you like to resume it or go back to the home screen?</p>
				</div>
				<div class="buttons">
					<div class="button" v-hammer:tap="resume">Resume Game</div>
					<div class="button red" v-hammer:tap="logout">Back to home</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import IpadThemeHome from '@/components/game/ipad-theme-home.vue'

export default {
	name: 'view-game',
	data() {
		return {
			popupResumeGameVisible: false
		}
	},
	computed: {
		...mapGetters([ 'app', 'game', 'logged_user', 'current_journal' ]),
		emblem_visible() {
			if (this.$route.params.category && this.$route.matched.length > 1 && this.$route.matched[1].path === '/game/profile') return false
			return true
		}
	},
	watch: {
		'game.room.state'(newValue, oldValue) {
			if (oldValue === 'running' && newValue === 'ending') {
				console.info("End day")
				this.$router.push({ name: 'summary' })
			}
		},
		'game.room.episode.episode_number'() {
			/*if (parseInt(episode_number) > 0) */location.reload()
		}
	},
	methods: {
		resume() {
			this.popupResumeGameVisible = false
		},
		logout() {
			this.$store.dispatch('logout')
		},
	},
	created() {
		if (this.game.scenario && this.game.scenario.id && parseInt(this.game.scenario.id) === 12) {
			if (localStorage.getItem('sre-first-solo-launch')) {
				this.popupResumeGameVisible = true
			} else
				localStorage.setItem('sre-first-solo-launch', true)
		}
	},
	components: { IpadThemeHome }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'

.main-view
	width 100%
	height 100%
	flex center center
	> .view
		width 100%
		height 100%
	> .resume-game-popup
		fixed 0
		flex center center
		z-index 100
		background-color var(--color-infobox-content-bg)
		.resume-game-popup-content
			width calc(100% - 32px)
			max-width 600px
			padding 32px 48px
			flex center center column
			gap 32px
			background-color var(--color-infobox-content-bg)
			border 3px solid var(--color-infobox-content-border)
			border-radius 8px
			text-align center
			font-size 2rem
			color var(--color-infobox-content-h1)
			.text
				p
					margin 0 0 8px 0
					&:last-child
						margin 0
			.buttons
				flex center center
				gap 16px
				.button
					display inline-block
					height 48px
					width 230px
					border-radius 4px
					background-color var(--color-infobox-content-button-bg)
					cursor pointer
					line-height (@height + 6px)
					font-weight 700
					color var(--color-infobox-content-button-text)
					text-transform uppercase
					font-size 2rem
					font-family 'Josefin Sans'
					&:active
						transform translateY(2px)
					&.red
						background-color red
	
.background
	position absolute
	left 50%
	top 50%
	transform translate(-50%, -50%)
	width 65%
	height 65%
	flex center center
	color var(--color-emblem)
	// background center center no-repeat
	// background-size contain
	// background-image url(../assets/img/emblems/gacc.svg)

	>>> .ui-icon
		width 100% !important
		height 100% !important
	
	&.classic
		background center center no-repeat
		background-size contain

	&[data-category="Law Enforcement"]
		background-image url(../assets/img/emblems/law-enforcement.svg)
	&[data-category="Citizens"]
		background-image url(../assets/img/emblems/citizens.svg)
	&[data-category="Government"]
		background-image url(../assets/img/emblems/government.svg)
	&[data-category="Media"]
		background-image url(../assets/img/emblems/media.svg)
		&[data-journal="usa-news"]
			background-image url(../assets/img/emblems/media-usa-news.svg)
		&[data-journal="the-buzz"]
			background-image url(../assets/img/emblems/media-the-buzz.svg)
		&[data-journal="capitol-news"]
			background-image url(../assets/img/emblems/media-capitol-news.svg)
		&[data-journal="world-news-network"]
			background-image url(../assets/img/emblems/media-world-news-network.svg)
		// &[data-journal="nola-post"]
		// 	background-image url(../assets/img/emblems/media-nola-post.svg)
		// &[data-journal="the-white-house"]
		// 	background-image url(../assets/img/emblems/media-the-white-house.svg)
	

</style>
